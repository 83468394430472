import PropTypes from 'prop-types';

export function TranslateIcon({
  className = '',
  width = '50px',
  height = '50px',
  fill = '#ffffff',
  stroke,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      viewBox="0 0 36 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.61125 11.5773C8.61125 9.41118 9.67875 8.1595 11.4966 8.1595C12.9862 8.1595 13.9859 9.07584 14.0909 10.3178H16.6V10.1053C16.4862 7.79296 14.36 6.20593 11.4769 6.20593C7.96813 6.20593 5.96875 8.25308 5.96875 11.5695V13.0259C5.96875 16.3403 7.94844 18.3484 11.4769 18.3484C14.3513 18.3484 16.4753 16.8043 16.6 14.568V14.3458H14.0909C13.9859 15.5448 13.0059 16.3929 11.4966 16.3929C9.65906 16.3929 8.61125 15.1841 8.61125 13.0259V11.5773ZM22.0425 11.5773C22.0425 9.41118 23.11 8.1595 24.9256 8.1595C26.4175 8.1595 27.4172 9.07584 27.5222 10.3178H30.0312V10.1053C29.9153 7.79296 27.7913 6.20593 24.9081 6.20593C21.3994 6.20593 19.4 8.25308 19.4 11.5695V13.0259C19.4 16.3403 21.3797 18.3484 24.9081 18.3484C27.7825 18.3484 29.9066 16.8043 30.0312 14.568V14.3458H27.5222C27.4172 15.5448 26.435 16.3929 24.9256 16.3929C23.0903 16.3929 22.0425 15.1841 22.0425 13.0259V11.5773Z"
        fill={fill}
      />
      <path
        d="M31.125 2.3066C31.7052 2.3066 32.2616 2.51201 32.6718 2.87764C33.082 3.24327 33.3125 3.73918 33.3125 4.25626V19.8536C33.3125 20.3707 33.082 20.8666 32.6718 21.2322C32.2616 21.5978 31.7052 21.8032 31.125 21.8032H4.875C4.29484 21.8032 3.73844 21.5978 3.3282 21.2322C2.91797 20.8666 2.6875 20.3707 2.6875 19.8536V4.25626C2.6875 3.73918 2.91797 3.24327 3.3282 2.87764C3.73844 2.51201 4.29484 2.3066 4.875 2.3066H31.125ZM4.875 0.356934C3.71468 0.356934 2.60188 0.767754 1.78141 1.49902C0.960936 2.23029 0.5 3.2221 0.5 4.25626L0.5 19.8536C0.5 20.8877 0.960936 21.8796 1.78141 22.6108C2.60188 23.3421 3.71468 23.7529 4.875 23.7529H31.125C32.2853 23.7529 33.3981 23.3421 34.2186 22.6108C35.0391 21.8796 35.5 20.8877 35.5 19.8536V4.25626C35.5 3.2221 35.0391 2.23029 34.2186 1.49902C33.3981 0.767754 32.2853 0.356934 31.125 0.356934H4.875Z"
        fill={fill}
      />
    </svg>
  );
}

TranslateIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default TranslateIcon;
