import { useEffect, useState } from 'react';

const useFetch = (getFunction, isMutation = false) => {
  const [dataFromServer, setDataFromServer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await getFunction();

      if (response?.data) {
        setDataFromServer(response);
      } else {
        setError(response);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (isMutation) return;
    if (!dataFromServer) getData().then(() => setLoading(false));
  }, [isMutation, dataFromServer]);

  useEffect(() => {
    if (dataFromServer) {
      setResponse(dataFromServer);
    }
  }, [dataFromServer]);

  return { dataFromServer, loading, error, getData, response };
};

export default useFetch;
