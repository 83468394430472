import PropTypes from 'prop-types';

import MuteIcon from '../icons/MuteIcon';
import PlayIcon from '../icons/PlayIcon';
import PauseIcon from '../icons/PauseIcon';
import UnmutedIcon from '../icons/UnmutedIcon';
import RewindIcon from '../icons/RewindIcon';
import FastForwardIcon from '../icons/FastForwardIcon';
import TranslateIcon from '../icons/TranslateIcon';
import KeyboardIcon from '../icons/KeyboardIcon';

import styles from './VideoControls.module.scss';

function VideoControls({
  showCC = false,
  toggleSubtitles,
  togglePlay,
  toggleMute,
  playerState = {},
  isPlaying,
  showTypeQuestionInput,
  toggleTypeQuestionInput,
  shouldShowKeyboard = false,
  handleRewind,
  buttonBackgroundColor,
}) {
  return (
    <div className={styles.videoControls}>
      <button className={styles.btnPlay} type="button" onClick={togglePlay}>
        {!isPlaying ? <PlayIcon /> : <PauseIcon />}
      </button>

      <button className={styles.btnPlay} type="button" onClick={() => handleRewind(false)}>
        <RewindIcon />
      </button>
      <button className={styles.btnPlay} type="button" onClick={() => handleRewind(true)}>
        <FastForwardIcon />
      </button>
      <button className={styles.btnMute} type="button" onClick={toggleMute}>
        {!playerState.isMuted ? <UnmutedIcon /> : <MuteIcon />}
      </button>
      <button
        className={`${styles.btnCC} ${showCC && styles.btnCCActive}`}
        type="button"
        onClick={() => toggleSubtitles(prevState => !prevState)}
      >
        <TranslateIcon
          stroke={showCC ? buttonBackgroundColor : ''}
          fill={showCC ? buttonBackgroundColor : '#ffffff'}
        />
      </button>
      {shouldShowKeyboard && (
        <button
          className={`${styles.btnTyping} ${showTypeQuestionInput && styles.btnTypingActive}`}
          type="button"
          onClick={() => toggleTypeQuestionInput(prevState => !prevState)}
        >
          <KeyboardIcon
            stroke={showTypeQuestionInput ? buttonBackgroundColor : ''}
            fill={showTypeQuestionInput ? buttonBackgroundColor : '#ffffff'}
          />
        </button>
      )}
    </div>
  );
}

VideoControls.propTypes = {
  showCC: PropTypes.bool,
  toggleSubtitles: PropTypes.func,
  togglePlay: PropTypes.func,
  toggleMute: PropTypes.func,
  handleRewind: PropTypes.func,
  playerState: PropTypes.object,
  showTypeQuestionInput: PropTypes.bool,
  toggleTypeQuestionInput: PropTypes.func,
  shouldShowKeyboard: PropTypes.bool,
};

export default VideoControls;
