import { useCallback, useRef, useState } from 'react';

const useLongPress = (
  startListening,
  stopListening,
  onClick,
  { shouldPreventDefault = true, delay = 200 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = useCallback(
    event => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', event.preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        startListening(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [startListening, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = false) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick();

      longPressTriggered && stopListening();
      setLongPressTriggered(false);

      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', event.preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  const memoedEvents = {
    onMouseDown: e => start(e),
    onMouseUp: e => clear(e),
    onMouseLeave: e => clear(e, false),
    onTouchStart: e => start(e),
    onTouchEnd: e => clear(e),
    onTouchCancel: e => clear(e),
    onClick: e => clear(e, false),
  };

  return { memoedEvents, longPressTriggered };
};

export default useLongPress;
