import React, { useEffect } from 'react';

import { useExperienceContext } from '../../context/ExperienceContext';

import styles from './QuestionsGroup.module.scss';

function Questions() {
  const { questionsGroups, selectedGroup, setSelectedGroup, setFilteredQuestions } =
    useExperienceContext();

  const getStyleForGroupButton = groupName =>
    groupName === selectedGroup ? styles.buttonActive : styles.buttonNotActive;

  const handleActiveGroup = e => {
    setSelectedGroup(e.target.name);
  };

  const filterByGroup = () => {
    let filteredQuestionsByGroup = [];
    if (selectedGroup === 'All') {
      filteredQuestionsByGroup = questionsGroups[0]?.questions;
    } else {
      const group = questionsGroups.filter(group => group.name === selectedGroup)[0];
      filteredQuestionsByGroup = group?.questions;
    }
    setFilteredQuestions(filteredQuestionsByGroup);
  };

  useEffect(() => {
    if (questionsGroups) {
      filterByGroup();
    }
  }, [questionsGroups, selectedGroup]);

  return (
    <ul className={styles.questionsGroupWrapper}>
      {questionsGroups[1] &&
        questionsGroups.map(
          group =>
            group?.name && (
              <li key={group.id} className={styles.listItem}>
                <button
                  type="button"
                  name={group.name}
                  value={group.id}
                  className={`${styles.questionsGroupButton} ${getStyleForGroupButton(group.name)}`}
                  onClick={e => handleActiveGroup(e)}
                >
                  {group.name}
                </button>
              </li>
            )
        )}
    </ul>
  );
}

export default Questions;
