import React, { useState } from 'react';
import { useDrawerContext } from '../../context/DrawerContext';

import { useExperienceContext } from '../../context/ExperienceContext';

import ArrowRightIcon from '../icons/ArrowRightIcon';
import InfoIcon from '../icons/InfoIcon';
import ConversationDescription from '../ConversationDescription/ConversationDescription';
import ConversationBasicInformation from '../ConversationBasicInformation/ConversationBasicInformation';

import styles from './OverlayInfo.module.scss';

function OverlayInfo() {
  const [showDescription, setShowDescription] = useState(false);

  const { openedPanel, handlePanelOpen } = useDrawerContext();
  const { experience } = useExperienceContext();
  const {
    title,
    brief,
    callToActionUrl,
    website,
    instagram,
    linkedIn,
    subtitle,
    callToActionText,
  } = experience.conversation;

  if (!title && !brief) return null;

  return (
    <div className={`${styles.basicInfo} ${openedPanel.infos ? styles.opened : ''}`}>
      <div className={styles.basicHolder}>
        {title && (
          <ConversationBasicInformation
            title={title}
            handleShowDescription={() => setShowDescription(!showDescription)}
            showDescription={showDescription}
            shouldShowDescription={brief}
            callToActionUrl={callToActionUrl}
            subtitle={subtitle}
            callToActionText={callToActionText}
          />
        )}
        {brief && showDescription && (
          <ConversationDescription
            brief={brief}
            website={website}
            instagram={instagram}
            linkedIn={linkedIn}
          />
        )}
      </div>
      <button className={styles.btn} type="button" onClick={() => handlePanelOpen('infos')}>
        {!openedPanel.infos ? <InfoIcon /> : <ArrowRightIcon />}
      </button>
    </div>
  );
}

export default React.memo(OverlayInfo);
