import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

function Loader({ isLoading = false, theme = 'dark' }) {
  if (!isLoading) return null;

  return (
    <div
      className={`${styles.loaderWrapper} ${isLoading ? styles.show : styles.hide} ${
        styles[theme]
      }`}
    >
      <div className={styles.loader} />
    </div>
  );
}

Loader.propTypes = {
  theme: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default Loader;
