import React from 'react';
import PropTypes from 'prop-types';

import styles from './SingleQuestion.module.scss';

function SingleQuestion({ question = {}, handleQuestionClick }) {
  if (!question) return;

  return (
    <li className={styles.listItem}>
      <button
        name="question"
        className={styles.buttonNoStyle}
        type="button"
        onClick={() => handleQuestionClick(question)}
      >
        {question.text}
      </button>
    </li>
  );
}

SingleQuestion.propTypes = {
  question: PropTypes.object,
  handleQuestionClick: PropTypes.func,
};

export default SingleQuestion;
