import { createContext, useContext, useState, useMemo } from 'react';
import { useExperienceContext } from './ExperienceContext';
import useScreenSize from '../hooks/useScreenSize';

const DrawerContext = createContext({});

export function useDrawerContext() {
  return useContext(DrawerContext);
}

const defaultDesktopPanelState = {
  questions: true,
  infos: false,
  videoControls: false,
};

const defaultTabletPanelState = {
  questions: false,
  infos: false,
  videoControls: false,
};

export function DrawerProvider({ children }) {
  const { height, width } = useScreenSize();
  const [firstTimeQuestionsOpen, setFirstTimeQuestionOpen] = useState(true);
  const { setFullScreenVideo } = useExperienceContext();

  const isTablet = width < 992 || height < 768;

  const [openedPanel, setOpenedPanel] = useState(
    isTablet ? defaultTabletPanelState : defaultDesktopPanelState
  );

  const handlePanelOpen = prop => {
    if (!isTablet) {
      setOpenedPanel(prev => ({ ...prev, [prop]: !openedPanel[prop] }));

      if (prop === 'questions') {
        setFullScreenVideo(prev => !prev);
        setFirstTimeQuestionOpen(false);
      }
    } else {
      setOpenedPanel({ ...defaultTabletPanelState, [prop]: !openedPanel[prop] });
      if (prop === 'questions') {
        setFirstTimeQuestionOpen(false);
      }
    }
  };

  const memoedValue = useMemo(
    () => ({ openedPanel, handlePanelOpen, firstTimeQuestionsOpen }),
    [openedPanel]
  );

  return <DrawerContext.Provider value={memoedValue}>{children}</DrawerContext.Provider>;
}
