import PropTypes from 'prop-types';

export function QuestionIcon({ className = '', width = '175px', height = '37px' }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 175 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9733 30.1214C29.8923 29.5628 29.6133 29.0484 29.1836 28.6654C28.7538 28.2824 28.1998 28.0543 27.6154 28.0199C27.031 27.9855 26.4523 28.1468 25.9773 28.4765C25.5023 28.8062 25.1602 29.284 25.0092 29.8289C24.8582 30.3739 24.9074 30.9523 25.1486 31.4662C25.3898 31.9802 25.808 32.3979 26.3324 32.6487C26.8568 32.8995 27.4551 32.9678 28.0259 32.8421C28.5966 32.7164 29.1047 32.4045 29.4639 31.9591C29.6743 31.7024 29.828 31.4069 29.9156 31.0909C30.0031 30.7749 30.0228 30.445 29.9733 30.1214Z"
        fill="white"
      />
      <path
        d="M17.3465 21.1585C17.2564 21.1585 17.1699 21.1492 17.081 21.1458L22.8473 28.5673L26.8344 25.7042L24.8823 23.1814C26.8619 21.4819 28.1605 19.1677 28.5523 16.6413C28.9442 14.1149 28.4044 11.5361 27.0269 9.35343C25.6495 7.17072 23.5214 5.52215 21.0128 4.69442C18.5042 3.86669 15.7739 3.91216 13.2965 4.82291C10.8191 5.73367 8.75145 7.4521 7.4531 9.67937C6.15475 11.9066 5.70783 14.5018 6.19007 17.0137C6.67231 19.5255 8.05319 21.7951 10.0926 23.4277C12.132 25.0602 14.7009 25.9526 17.3525 25.9495C17.5279 25.9495 17.6985 25.9345 17.8703 25.9241L12.3274 18.7907C11.4435 17.6964 10.9704 16.3459 10.9856 14.9594C11.0007 13.5728 11.5031 12.2322 12.4108 11.156C13.3185 10.0799 14.5779 9.33178 15.9836 9.03373C17.3893 8.73569 18.8584 8.90529 20.1512 9.51489C21.444 10.1245 22.4843 11.1381 23.1023 12.3904C23.7203 13.6427 23.8796 15.0597 23.5542 16.4104C23.2288 17.7611 22.4379 18.9658 21.3106 19.8278C20.1832 20.6899 18.7859 21.1586 17.3465 21.1573V21.1585Z"
        fill="white"
      />
      <path
        d="M36.4708 4.77838V18.2659C36.4708 19.8694 36.8869 21.0771 37.7191 21.889C38.5716 22.7009 39.7488 23.1068 41.2508 23.1068C42.7731 23.1068 43.9504 22.7009 44.7826 21.889C45.635 21.0771 46.0613 19.8694 46.0613 18.2659V4.77838H49.5321V18.205C49.5321 19.9303 49.1566 21.3917 48.4056 22.5892C47.6546 23.7868 46.6499 24.6798 45.3915 25.2685C44.133 25.8571 42.7427 26.1514 41.2204 26.1514C39.6981 26.1514 38.3077 25.8571 37.0493 25.2685C35.8112 24.6798 34.8268 23.7868 34.0961 22.5892C33.3654 21.3917 33 19.9303 33 18.205V4.77838H36.4708Z"
        fill="white"
      />
      <path
        d="M57.6438 7.5794V13.7904H64.9508V16.6218H57.6438V23.1068H65.8642V25.9383H54.173V4.74793H65.8642V7.5794H57.6438Z"
        fill="white"
      />
      <path
        d="M77.2331 26.1514C75.8123 26.1514 74.5336 25.9078 73.3969 25.4207C72.2603 24.9133 71.3672 24.2029 70.7177 23.2895C70.0682 22.3761 69.7434 21.3105 69.7434 20.0927H73.4578C73.539 21.006 73.8942 21.757 74.5234 22.3457C75.1729 22.9343 76.0762 23.2286 77.2331 23.2286C78.4307 23.2286 79.3643 22.9444 80.0341 22.3761C80.7039 21.7875 81.0389 21.0365 81.0389 20.1231C81.0389 19.4127 80.8257 18.8342 80.3995 18.3877C79.9935 17.9412 79.476 17.5961 78.8468 17.3525C78.2378 17.109 77.3853 16.8451 76.2893 16.5609C74.9091 16.1956 73.7826 15.8302 72.9098 15.4649C72.0573 15.0792 71.3266 14.4906 70.7177 13.699C70.1088 12.9074 69.8043 11.852 69.8043 10.5326C69.8043 9.31481 70.1088 8.24921 70.7177 7.33583C71.3266 6.42246 72.1791 5.7222 73.2752 5.23507C74.3712 4.74793 75.6398 4.50436 77.0809 4.50436C79.1309 4.50436 80.8054 5.02194 82.1045 6.0571C83.4238 7.07197 84.1545 8.47248 84.2966 10.2586H80.4604C80.3995 9.48734 80.0341 8.82768 79.3643 8.27965C78.6945 7.73163 77.8116 7.45762 76.7155 7.45762C75.721 7.45762 74.9091 7.71133 74.2799 8.21876C73.6507 8.72619 73.336 9.4569 73.336 10.4109C73.336 11.0604 73.5289 11.5983 73.9145 12.0245C74.3205 12.4304 74.8279 12.7552 75.4368 12.9988C76.0457 13.2423 76.8779 13.5062 77.9334 13.7904C79.3339 14.176 80.4705 14.5617 81.3433 14.9473C82.2364 15.333 82.9874 15.9317 83.5963 16.7436C84.2255 17.5352 84.5401 18.6008 84.5401 19.9404C84.5401 21.0162 84.2458 22.031 83.6572 22.985C83.0889 23.939 82.2465 24.7103 81.1302 25.2989C80.0341 25.8672 78.7351 26.1514 77.2331 26.1514Z"
        fill="white"
      />
      <path
        d="M102.428 4.77838V7.60984H96.7951V25.9383H93.3242V7.60984H87.6613V4.77838H102.428Z"
        fill="white"
      />
      <path d="M109.535 4.77838V25.9383H106.064V4.77838H109.535Z" fill="white" />
      <path
        d="M123.865 26.1514C121.896 26.1514 120.08 25.6947 118.415 24.7813C116.771 23.8477 115.462 22.5588 114.488 20.9147C113.534 19.2503 113.057 17.383 113.057 15.3127C113.057 13.2423 113.534 11.3851 114.488 9.74106C115.462 8.09698 116.771 6.81825 118.415 5.90487C120.08 4.9712 121.896 4.50436 123.865 4.50436C125.854 4.50436 127.671 4.9712 129.315 5.90487C130.979 6.81825 132.289 8.09698 133.243 9.74106C134.197 11.3851 134.674 13.2423 134.674 15.3127C134.674 17.383 134.197 19.2503 133.243 20.9147C132.289 22.5588 130.979 23.8477 129.315 24.7813C127.671 25.6947 125.854 26.1514 123.865 26.1514ZM123.865 23.1372C125.266 23.1372 126.514 22.8226 127.61 22.1934C128.706 21.5439 129.559 20.6305 130.168 19.4533C130.797 18.2558 131.111 16.8755 131.111 15.3127C131.111 13.7498 130.797 12.3797 130.168 11.2025C129.559 10.0252 128.706 9.12199 127.61 8.49278C126.514 7.86356 125.266 7.54895 123.865 7.54895C122.465 7.54895 121.216 7.86356 120.12 8.49278C119.024 9.12199 118.162 10.0252 117.533 11.2025C116.924 12.3797 116.619 13.7498 116.619 15.3127C116.619 16.8755 116.924 18.2558 117.533 19.4533C118.162 20.6305 119.024 21.5439 120.12 22.1934C121.216 22.8226 122.465 23.1372 123.865 23.1372Z"
        fill="white"
      />
      <path
        d="M155.595 25.9383H152.125L141.682 10.1369V25.9383H138.211V4.74793H141.682L152.125 20.5189V4.74793H155.595V25.9383Z"
        fill="white"
      />
      <path
        d="M167.247 26.1514C165.826 26.1514 164.548 25.9078 163.411 25.4207C162.274 24.9133 161.381 24.2029 160.732 23.2895C160.082 22.3761 159.757 21.3105 159.757 20.0927H163.472C163.553 21.006 163.908 21.757 164.537 22.3457C165.187 22.9343 166.09 23.2286 167.247 23.2286C168.445 23.2286 169.378 22.9444 170.048 22.3761C170.718 21.7875 171.053 21.0365 171.053 20.1231C171.053 19.4127 170.84 18.8342 170.413 18.3877C170.008 17.9412 169.49 17.5961 168.861 17.3525C168.252 17.109 167.399 16.8451 166.303 16.5609C164.923 16.1956 163.797 15.8302 162.924 15.4649C162.071 15.0792 161.341 14.4906 160.732 13.699C160.123 12.9074 159.818 11.852 159.818 10.5326C159.818 9.31481 160.123 8.24921 160.732 7.33583C161.341 6.42246 162.193 5.7222 163.289 5.23507C164.385 4.74793 165.654 4.50436 167.095 4.50436C169.145 4.50436 170.819 5.02194 172.118 6.0571C173.438 7.07197 174.168 8.47248 174.311 10.2586H170.474C170.413 9.48734 170.048 8.82768 169.378 8.27965C168.708 7.73163 167.826 7.45762 166.73 7.45762C165.735 7.45762 164.923 7.71133 164.294 8.21876C163.665 8.72619 163.35 9.4569 163.35 10.4109C163.35 11.0604 163.543 11.5983 163.928 12.0245C164.334 12.4304 164.842 12.7552 165.451 12.9988C166.06 13.2423 166.892 13.5062 167.947 13.7904C169.348 14.176 170.485 14.5617 171.357 14.9473C172.25 15.333 173.001 15.9317 173.61 16.7436C174.24 17.5352 174.554 18.6008 174.554 19.9404C174.554 21.0162 174.26 22.031 173.671 22.985C173.103 23.939 172.261 24.7103 171.144 25.2989C170.048 25.8672 168.749 26.1514 167.247 26.1514Z"
        fill="white"
      />
    </svg>
  );
}

QuestionIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default QuestionIcon;
