import PropTypes from 'prop-types';

import styles from './CorporateLogo.module.scss';

function CorporateLogo({ logoSrc = '', bottomLeftLogoExternalUrl = '' }) {
  return (
    <a
      href={bottomLeftLogoExternalUrl}
      target="_blank"
      rel="noreferrer"
      className={styles.corporateLogo}
    >
      <img src={logoSrc} alt="logo" className={styles.corporateImg} />
    </a>
  );
}

CorporateLogo.propTypes = {
  logoSrc: PropTypes.string,
  bottomLeftLogoExternalUrl: PropTypes.string,
};

export default CorporateLogo;
