import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import useFetch from '../hooks/useFetch';
import useIsMounted from '../hooks/useIsMounted';

import { getDistributedConversationById, getQuestionGroups } from '../api/requests';

import Error from '../components/Error/Error';

import { MeasureConnectionSpeed, handleType } from '../utils/detectInternetSpeed';

import { CONVERSATION_ID_MESSAGE, INVALID_URL } from '../constants/experience';

const ExperienceContext = createContext({});

export function useExperienceContext() {
  return useContext(ExperienceContext);
}

export function ExperienceProvider({ children }) {
  const isTablet = document.body.clientWidth < 992;

  const queryString = window.location.search;
  const distributedConversationId = queryString.substring(1);

  const errorMessage = {
    statusText: INVALID_URL,
    message: CONVERSATION_ID_MESSAGE,
  };

  if (!distributedConversationId) return <Error error={errorMessage} />;

  const { dataFromServer, error, loading } = useFetch(() =>
    getDistributedConversationById(distributedConversationId)
  );

  const {
    dataFromServer: questionGroupsFromServer,
    error: groupsError,
    loading: loadingGroups,
    getData: fetchQuestionGroups,
  } = useFetch(() => getQuestionGroups(dataFromServer?.data?.conversation?.id), true);
  const [experience, setExperience] = useState(dataFromServer?.data);

  const [storedSubtitleUrl, setStoredSubtitleUrl] = useState('');
  const [storedVideoUrl, setStoredVideoUrl] = useState('');
  const [storedVideoId, setStoredVideoId] = useState(null);
  const [lowerQualityVideos, setLowerQualityVideos] = useState({});
  const [selectedGroup, setSelectedGroup] = useState('All');
  const [questionsGroups, setQuestionsGroups] = useState([]);
  const [externalUrl, setExternalUrl] = useState(null);
  const [externalUrlDescription, setExternalUrlDescription] = useState(null);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [fullScreenVideo, setFullScreenVideo] = useState(isTablet);
  const [showStartExperience, setShowStartExperience] = useState(true);

  const [effectiveType, handleEffectiveType] = useState('');

  useIsMounted(() => {
    MeasureConnectionSpeed().then(res => handleEffectiveType(handleType(res)));
  });

  const connectionType = effectiveType;

  useEffect(() => {
    if (dataFromServer) {
      setExperience(dataFromServer.data);
      fetchQuestionGroups();
      setStoredVideoUrl(dataFromServer.data?.conversation?.idleVideo?.url);
      setLowerQualityVideos(dataFromServer.data?.conversation?.idleVideo.lowerQualityVideos);
    }
  }, [dataFromServer]);

  useEffect(() => {
    if (questionGroupsFromServer?.data) {
      setQuestionsGroups(questionGroupsFromServer.data);
      setFilteredQuestions(questionGroupsFromServer.data[0].questions);
    }
  }, [questionGroupsFromServer?.data]);

  const memoedValue = useMemo(
    () => ({
      loading,
      experience,
      storedVideoUrl,
      setStoredVideoUrl,
      questionsGroups,
      setQuestionsGroups,
      selectedGroup,
      setSelectedGroup,
      fullScreenVideo,
      setFullScreenVideo,
      error,
      distributedConversationId,
      filteredQuestions,
      setFilteredQuestions,
      loadingGroups,
      groupsError,
      externalUrl,
      storedSubtitleUrl,
      externalUrlDescription,
      setExternalUrlDescription,
      setExternalUrl,
      setStoredSubtitleUrl,
      showStartExperience,
      setShowStartExperience,
      lowerQualityVideos,
      setLowerQualityVideos,
      connectionType,
      handleEffectiveType,
      storedVideoId,
      setStoredVideoId,
    }),
    [
      loading,
      experience,
      storedVideoUrl,
      setStoredVideoUrl,
      questionsGroups,
      setQuestionsGroups,
      selectedGroup,
      setSelectedGroup,
      fullScreenVideo,
      setFullScreenVideo,
      error,
      distributedConversationId,
      filteredQuestions,
      setFilteredQuestions,
      loadingGroups,
      groupsError,
      externalUrl,
      setExternalUrl,
      externalUrlDescription,
      setExternalUrlDescription,
      storedSubtitleUrl,
      setStoredSubtitleUrl,
      showStartExperience,
      setShowStartExperience,
      lowerQualityVideos,
      setLowerQualityVideos,
      connectionType,
      handleEffectiveType,
      storedVideoId,
      setStoredVideoId,
    ]
  );

  return <ExperienceContext.Provider value={memoedValue}>{children}</ExperienceContext.Provider>;
}
