import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    let message;
    if (!error.response.data) {
      message = error.message;
    } else {
      message = error.response?.data.Message || error.response?.data.title;
    }

    const { status, statusText } = error.response;

    return { message, status, statusText };
  }
);

export default api;
