import PropTypes from 'prop-types';

export function LinkedinIcon({ className = '', width = '20px', height = '20px' }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666626 1.89196C0.666626 1.56698 0.795723 1.25531 1.02552 1.02552C1.25531 0.795725 1.56698 0.666628 1.89196 0.666628H14.1066C14.2677 0.666365 14.4272 0.69787 14.5761 0.759339C14.7249 0.820808 14.8602 0.911034 14.9741 1.02485C15.0881 1.13867 15.1784 1.27384 15.2401 1.42263C15.3017 1.57143 15.3334 1.73091 15.3333 1.89196V14.1066C15.3335 14.2677 15.3019 14.4272 15.2403 14.5761C15.1788 14.725 15.0885 14.8602 14.9746 14.9742C14.8608 15.0881 14.7255 15.1785 14.5767 15.2401C14.4279 15.3017 14.2684 15.3334 14.1073 15.3333H1.89196C1.73099 15.3333 1.5716 15.3016 1.42289 15.24C1.27418 15.1783 1.13907 15.088 1.02528 14.9742C0.911491 14.8603 0.821247 14.7252 0.759708 14.5764C0.698168 14.4277 0.666539 14.2683 0.666626 14.1073V1.89196ZM6.47196 6.25863H8.45796V7.25596C8.74463 6.68263 9.47796 6.16663 10.58 6.16663C12.6926 6.16663 13.1933 7.30863 13.1933 9.40396V13.2853H11.0553V9.88129C11.0553 8.68796 10.7686 8.01463 10.0406 8.01463C9.03063 8.01463 8.61063 8.74063 8.61063 9.88129V13.2853H6.47196V6.25863ZM2.80529 13.194H4.94396V6.16663H2.80529V13.1933V13.194ZM5.24996 3.87463C5.25399 4.05774 5.22141 4.23982 5.15412 4.41017C5.08683 4.58052 4.98619 4.73572 4.85811 4.86665C4.73003 4.99758 4.57709 5.10161 4.40826 5.17263C4.23943 5.24365 4.05812 5.28024 3.87496 5.28024C3.6918 5.28024 3.51049 5.24365 3.34166 5.17263C3.17283 5.10161 3.01989 4.99758 2.89181 4.86665C2.76373 4.73572 2.66309 4.58052 2.5958 4.41017C2.52851 4.23982 2.49593 4.05774 2.49996 3.87463C2.50787 3.5152 2.65622 3.17316 2.91322 2.92175C3.17022 2.67035 3.51544 2.52957 3.87496 2.52957C4.23448 2.52957 4.5797 2.67035 4.8367 2.92175C5.0937 3.17316 5.24204 3.5152 5.24996 3.87463Z"
        fill="white"
      />
    </svg>
  );
}

LinkedinIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default LinkedinIcon;
