import PropTypes from 'prop-types';
import MicIcon from '../icons/MicIcon';

import useLongPress from '../../hooks/useLongPress';

import { SPEECH_TOOLTIP } from '../../constants/experience';

import styles from './PressAndHoldButton.module.scss';

function PressAndHoldButton({ startListening, stopListening }) {
  const { memoedEvents, longPressTriggered } = useLongPress(startListening, stopListening);

  return (
    <div className={`${styles.speechActions} ${longPressTriggered ? styles.speechBorder : ''}`}>
      <button type="button" className={styles.speechButton} {...memoedEvents}>
        <span />
        <div className={styles.speechTooltip}>
          <MicIcon />
          <span className={styles.speechTooltipText}>{SPEECH_TOOLTIP}</span>
        </div>
      </button>
    </div>
  );
}

PressAndHoldButton.propTypes = {
  isHolding: PropTypes.bool,
};

export default PressAndHoldButton;
