import React, { useEffect, useState } from 'react';

// import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { isIOS } from 'react-device-detect';

import { useExperienceContext } from '../../context/ExperienceContext';
import { useDrawerContext } from '../../context/DrawerContext';
import { useVideoContext } from '../../context/VideoContext';

import { getVideoForAudio } from '../../api/requests';
import useFetch from '../../hooks/useFetch';
import useScreenSize from '../../hooks/useScreenSize';
import useIsMounted from '../../hooks/useIsMounted';

import findVideoQuality from '../../utils/findVideoQuality';

import PressAndHoldButton from '../PressAndHoldButton/PressAndHoldButton';
import VideoControls from '../VideoControls/VideoControls';
import OverlayInfo from '../OverlayInfo/OverlayInfo';
import TypeInYourQuestion from '../TypeInYourQuestion/TypeInYourQuestion';
import ExternalUrl from '../ExternalUrl/ExternalUrl';
import CorporateLogo from '../CorporateLogo/CorporateLogo';
import VideoProgressBar from '../VideoProgressBar/VideoProgressBar';
import HelperVideo from '../HelperVideo/HelperVideo';
import MainVideo from '../MainVideo/MainVideo';
import Loader from '../Loader/Loader';
import ToggleQuestionsButton from '../ToggleQuestionsButton/ToggleQuestionsButton';

import ArrowRightIcon from '../icons/ArrowRightIcon';

import styles from './Video.module.scss';
import useCustomAnalytics from '../../hooks/useCustomAnalytics';

// const appId = '2860d928-3361-44ab-a22d-c08d7b686389';
// const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
// SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);

function Video({ buttonBackgroundColor }) {
  const { height, setHeight } = useScreenSize();
  const vh = height * 0.01;
  const [showTypeQuestionInput, setShowTypeQuestionInput] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [typedQuestion, setTypedQuestion] = useState('');

  const {
    toggleMute,
    playerState,
    isPlaying,
    togglePlay,
    handleVideoProgress,
    handleOnTimeUpdate,
    handleOnLoadStart,
    unmuteVideo,
    playVideo,
    playVideoFromStart,
    handleRewind,
    references,
    isIdleVideoShown,
    isAnswerLoading,
  } = useVideoContext();
  const { videoElement, subtitleRef, progressRef, progressbarDoneRef, helperVideoRef } = references;
  const {
    experience,
    storedVideoUrl,
    fullScreenVideo,
    setFullScreenVideo,
    setStoredVideoUrl,
    setLowerQualityVideos,
    lowerQualityVideos,
    setStoredSubtitleUrl,
    storedSubtitleUrl,
    externalUrl,
    setExternalUrl,
    externalUrlDescription,
    setExternalUrlDescription,
    showStartExperience,
    setShowStartExperience,
    distributedConversationId,
    connectionType,
    storedVideoId,
    setStoredVideoId,
  } = useExperienceContext();

  const { handleCustomAnalytics, handleReset, getDuration } = useCustomAnalytics(
    videoElement,
    storedVideoId
  );

  const {
    transcript,
    finalTranscript,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    listening,
  } = useSpeechRecognition();
  const { openedPanel, handlePanelOpen } = useDrawerContext();

  const { dataFromServer: dataForRecordedQuestion, getData: getDataForAudio } = useFetch(
    () => getVideoForAudio(experience.conversation?.id, transcript, distributedConversationId, 2),
    true
  );
  const { dataFromServer: dataForTypedQuestion, getData: getDataForTypedQuestion } = useFetch(
    () =>
      getVideoForAudio(
        experience.conversation?.id,
        typedQuestion || '',
        distributedConversationId,
        3
      ),
    true
  );

  const idleVideoUrl = experience?.conversation?.idleVideo?.url;
  const isPlayingIdleVideo = storedVideoUrl === idleVideoUrl;
  const idleLowerQualityVideos = experience?.conversation?.idleVideo?.lowerQualityVideos;

  const startListening = () => {
    setStoredSubtitleUrl('');
    if (!isPlayingIdleVideo) {
      setStoredVideoUrl(idleVideoUrl);
      setLowerQualityVideos(idleLowerQualityVideos);
    }

    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-US',
    });
  };

  const stopListening = async () => {
    SpeechRecognition.stopListening();

    if (!isIOS) {
      unmuteVideo();
    }
  };

  const startExperience = () => {
    playVideo();
    unmuteVideo();
    setShowStartExperience(false);
    setFullScreenVideo(false);
    unmuteVideo();
  };

  useEffect(() => {
    if (!listening && finalTranscript) {
      getDataForAudio();
    }
  }, [finalTranscript, listening]);

  useEffect(() => {
    if (typedQuestion) {
      getDataForTypedQuestion(finalTranscript);
    }
  }, [typedQuestion]);

  useEffect(() => {
    if (dataForRecordedQuestion) {
      if (isSafari && !isIOS) playVideo();
      setStoredVideoUrl(dataForRecordedQuestion.data?.videoUrl);
      setLowerQualityVideos(dataForRecordedQuestion.data.lowerQualityVideos);
      setStoredSubtitleUrl(dataForRecordedQuestion.data?.subtitleUrl || '');
      setExternalUrl(dataForRecordedQuestion.data.externalUrl || '');
      setExternalUrlDescription(dataForRecordedQuestion.data.externalUrlDescription || '');
      resetTranscript();
      playVideo();
    }
  }, [dataForRecordedQuestion]);

  useEffect(() => {
    if (dataForTypedQuestion) {
      setTypedQuestion('');
      setStoredVideoUrl(dataForTypedQuestion.data.videoUrl);
      setLowerQualityVideos(dataForTypedQuestion.data.lowerQualityVideos);
      setStoredSubtitleUrl(dataForTypedQuestion.data?.subtitleUrl || '');
      setExternalUrl(dataForTypedQuestion.data.externalUrl || '');
      setExternalUrlDescription(dataForRecordedQuestion.data.externalUrlDescription || '');
      playVideo();
      playVideoFromStart();
    }
  }, [dataForTypedQuestion]);

  const handleShowIdleVideo = () => {
    setStoredVideoUrl(idleVideoUrl);
    setStoredVideoId(null);
    setLowerQualityVideos(idleLowerQualityVideos);

    setStoredSubtitleUrl('');
    setExternalUrl('');
    setExternalUrlDescription('');
  };

  useIsMounted(() => {
    if (!experience?.conversation.speechToTextEnabled) return;

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(stream => {
        window.localStream = stream;
      })
      .catch(err => {
        console.error('The following error occured:', err);
      });
  });

  document.getElementById('videoHolder')?.addEventListener('contextmenu', event => {
    event.preventDefault();
  });

  if (!browserSupportsSpeechRecognition) {
    console.error('Your browser does not support Speech to Text!');
  }

  if (!isMicrophoneAvailable) {
    console.error('Microphone is not available. Please allow access to microphone.');
  }

  return (
    <div className={styles.videoContainer}>
      <OverlayInfo />

      {experience.bottomLeftLogo && (
        <CorporateLogo
          logoSrc={experience.bottomLeftLogo.url}
          bottomLeftLogoExternalUrl={experience.bottomLeftLogo.externalUrl}
        />
      )}

      <div
        className={styles.videoWrapper}
        style={{
          '--vh': `${vh}px`,
        }}
      >
        <ToggleQuestionsButton />

        <div
          className={`${styles.videoHolder} ${!fullScreenVideo && styles.videoHolderActive} ${
            styles.activeVideo
          }`}
          id="videoHolder"
        >
          {connectionType ? (
            <>
              {idleVideoUrl && (
                <HelperVideo
                  src={findVideoQuality(connectionType, idleVideoUrl, idleLowerQualityVideos)}
                  handleOnClick={togglePlay}
                  className={styles.videoSrc}
                  helperVideoRef={helperVideoRef}
                  isPlayingIdleVideo={isPlayingIdleVideo}
                  storedVideoUrl={storedVideoUrl}
                  id="helper-video"
                />
              )}
              {storedVideoUrl && (
                <MainVideo
                  src={findVideoQuality(connectionType, storedVideoUrl, lowerQualityVideos)}
                  handleOnClick={togglePlay}
                  handleOnEnded={handleShowIdleVideo}
                  handleOnTimeUpdate={handleOnTimeUpdate}
                  handleMuted={playerState.isMuted}
                  videoElementRef={videoElement}
                  subtitleUrl={storedSubtitleUrl}
                  className={`${styles.videoSrc} ${styles.videoSrcMain}`}
                  handleLoop={isPlayingIdleVideo}
                  handleOnLoadStart={handleOnLoadStart}
                  isLoading={isAnswerLoading}
                  isConnectionSlow={connectionType !== '4g'}
                  handleOnDurationChange={getDuration}
                  handleReset={handleReset}
                  handleCustomAnalytics={handleCustomAnalytics}
                />
              )}
            </>
          ) : (
            <Loader isLoading theme="light" />
          )}
          <div className={styles.textContainer}>
            {externalUrl && (
              <ExternalUrl
                externalUrl={externalUrl}
                externalUrlDescription={externalUrlDescription}
              />
            )}
            {showCC && !isIdleVideoShown && (
              <div className={styles.subtitles}>
                <p ref={subtitleRef} />
              </div>
            )}

            {showTypeQuestionInput ? (
              <TypeInYourQuestion
                handleTypedQuestion={setTypedQuestion}
                unmuteVideo={unmuteVideo}
                setHeight={setHeight}
              />
            ) : null}
          </div>

          <VideoProgressBar
            progressRef={progressRef}
            handleVideoProgress={handleVideoProgress}
            progressbarDoneRef={progressbarDoneRef}
            shouldShowProgressBar={
              !isIdleVideoShown && openedPanel.videoControls && !isPlayingIdleVideo
            }
            key={storedVideoUrl}
          />

          {showStartExperience && isIOS && experience?.conversation.speechToTextEnabled ? (
            <button
              type="button"
              className={styles.startExperienceButton}
              onClick={startExperience}
            >
              Let&apos;s Talk
            </button>
          ) : (
            experience?.conversation.speechToTextEnabled && (
              <PressAndHoldButton startListening={startListening} stopListening={stopListening} />
            )
          )}
          <div
            className={`${styles.videoControlsHolder} ${
              openedPanel.videoControls && styles.openedControls
            }`}
          >
            <button
              onClick={() => handlePanelOpen('videoControls')}
              type="button"
              className={styles.videoControlsBtn}
            >
              <ArrowRightIcon />
            </button>
            {videoElement.current && openedPanel.videoControls ? (
              <VideoControls
                showCC={showCC}
                toggleSubtitles={setShowCC}
                togglePlay={togglePlay}
                toggleMute={toggleMute}
                playerState={playerState}
                isPlaying={isPlaying}
                shouldShowKeyboard={experience?.conversation?.speechToTextEnabled}
                showTypeQuestionInput={showTypeQuestionInput}
                toggleTypeQuestionInput={setShowTypeQuestionInput}
                handleRewind={handleRewind}
                videoElementRef={videoElement}
                buttonBackgroundColor={buttonBackgroundColor}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
