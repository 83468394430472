import React from 'react';

import { sendClickedQuestionInteraction } from '../../api/requests';
import { MeasureConnectionSpeed, handleType } from '../../utils/detectInternetSpeed';

import { useDrawerContext } from '../../context/DrawerContext';
import { useExperienceContext } from '../../context/ExperienceContext';
import { useVideoContext } from '../../context/VideoContext';

import SingleQuestion from '../SingleQuestion/SingleQuestion';

import styles from './QuestionsList.module.scss';

function QuestionList() {
  const {
    filteredQuestions,
    setStoredVideoUrl,
    setStoredSubtitleUrl,
    setLowerQualityVideos,
    setExternalUrl,
    setExternalUrlDescription,
    distributedConversationId,
    handleEffectiveType,
    setStoredVideoId,
  } = useExperienceContext();
  const { unmuteVideo, playVideoFromStart } = useVideoContext();

  const isTablet = window.innerWidth < 992 || window.innerHeight < 768;

  const { handlePanelOpen } = useDrawerContext();

  const handleQuestionClick = question => {
    MeasureConnectionSpeed().then(res => handleEffectiveType(handleType(res)));

    setStoredVideoUrl(question.videoUrl);
    setLowerQualityVideos(question.lowerQualityVideos);
    setStoredSubtitleUrl(question?.subtitleUrl);
    setExternalUrl(question.externalUrl);
    setExternalUrlDescription(question.externalUrlDescription);
    setStoredVideoId(question.id);

    sendClickedQuestionInteraction(distributedConversationId, question.id);

    unmuteVideo();
    playVideoFromStart();

    if (isTablet) {
      handlePanelOpen('questions');
    }
  };

  return (
    <div className={styles.questionListHolder}>
      <div className={styles.questionListWrapper}>
        <ul className={styles.questionList}>
          {filteredQuestions?.map(question => (
            <SingleQuestion
              question={question}
              key={question.id}
              handleQuestionClick={handleQuestionClick}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QuestionList;
