import PropTypes from 'prop-types';

export function FastForwardIcon({
  className = '',
  width = '50px',
  height = '50px',
  fill = '#ffffff',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 26 26"
    >
      <path
        d="M11.3334 12V25.3333H8.66671V14.6667H6.00004V12H11.3334ZM22 14.6667V22.6667C22 24.1467 20.8134 25.3333 19.3334 25.3333H16.6667C15.9595 25.3333 15.2812 25.0524 14.7811 24.5523C14.281 24.0522 14 23.3739 14 22.6667V14.6667C14 13.9594 14.281 13.2811 14.7811 12.781C15.2812 12.281 15.9595 12 16.6667 12H19.3334C20.0406 12 20.7189 12.281 21.219 12.781C21.7191 13.2811 22 13.9594 22 14.6667ZM16.6667 14.6667V22.6667H19.3334V14.6667H16.6667ZM13.3334 0C16.8667 0 20.0667 1.33333 22.5334 3.46667L26 0V9.33333H16.6667L20.16 5.84C18.3067 4.29333 15.9467 3.33333 13.3334 3.33333C8.61337 3.33333 4.60004 6.41333 3.20004 10.6667L0.0400391 9.62667C1.89337 4.04 7.13337 0 13.3334 0Z"
        fill="white"
      />
    </svg>
  );
}

FastForwardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default FastForwardIcon;
