import { useDrawerContext } from '../../context/DrawerContext';

import ArrowRightIcon from '../icons/ArrowRightIcon';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import AskAQuestionIcon from '../icons/AskAQuestionIcon';

import styles from './ToggleQuestionsButton.module.scss';

function ToggleQuestionsButton() {
  const { openedPanel, handlePanelOpen, firstTimeQuestionsOpen } = useDrawerContext();

  return (
    <button
      className={`${firstTimeQuestionsOpen ? styles.questionTogglerAnimated : ''} ${styles.btnFS} ${
        openedPanel.questions && styles.openedQuestionsButton
      }`}
      type="button"
      onClick={() => handlePanelOpen('questions')}
    >
      {!openedPanel.questions ? (
        <div className={styles.questionsClosedWrapper}>
          <ArrowLeftIcon />
          <AskAQuestionIcon />
        </div>
      ) : (
        <ArrowRightIcon />
      )}
    </button>
  );
}

export default ToggleQuestionsButton;
