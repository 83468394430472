import PropTypes from 'prop-types';

import styles from './Error.module.scss';

function Error({ error }) {
  return (
    <div className={styles.errorPage}>
      {error.status && <h1>{error.status}</h1>}
      {error.statusText && <h5>{error.statusText}</h5>}
      {error.message && <p className={styles.errorText}>{error.message}</p>}
    </div>
  );
}

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Error;
