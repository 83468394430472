import PropTypes from 'prop-types';
import { VIDEO_QUALITIES } from '../constants/videoQualities';

const findVideoQuality = (connectionType = '4g', url = '', lowerQualityVideos = {}) => {
  if (connectionType === '4g') {
    return url;
  }

  const videoQuality = VIDEO_QUALITIES[connectionType];

  return lowerQualityVideos[videoQuality];
};

findVideoQuality.PropTypes = {
  connectionType: PropTypes.string,
  url: PropTypes.string,
  lowerQualityVideos: PropTypes.object,
};

export default findVideoQuality;
