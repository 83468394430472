import { useRef, useState } from 'react';

const useVideoPlayer = () => {
  const videoElement = useRef(null);
  const helperVideoRef = useRef(null);
  const subtitleRef = useRef(null);
  const progressRef = useRef(null);
  const progressbarDoneRef = useRef(null);
  const references = { videoElement, subtitleRef, progressRef, progressbarDoneRef, helperVideoRef };
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);

  const [playerState, setPlayerState] = useState({
    speed: 1,
    isMuted: true,
  });
  const [isPlaying, handleIsPlaying] = useState(true);

  const togglePlay = () => {
    if (!videoElement.current) return;

    handleIsPlaying(prevState => !prevState);

    !isPlaying ? videoElement.current.play() : videoElement.current.pause();
  };

  const playVideo = () => {
    if (!videoElement.current) return;

    handleIsPlaying(true);
  };

  const [isIdleVideoShown, setIsIdleVideoShown] = useState(false);

  const handleOnTimeUpdate = () => {
    if (!videoElement.current || !progressRef.current || !progressbarDoneRef.current) return;

    const progressPercent =
      (videoElement.current.currentTime / videoElement.current.duration) * 100;

    const cueText = videoElement.current.textTracks?.[0].activeCues?.[0]?.text;
    const showBlur = videoElement.current.readyState < 4 && videoElement.current.currentTime < 0.3;

    setIsAnswerLoading(showBlur);
    videoElement.current.style.opacity = showBlur ? '0' : '1';

    setIsIdleVideoShown(!videoElement.current.readyState);

    if (progressPercent) {
      progressRef.current.value = progressPercent;
      progressbarDoneRef.current.style.width = `${progressPercent}%`;
      if (subtitleRef.current) {
        subtitleRef.current.innerText = cueText || '';
      }
    }
  };

  const handleOnLoadStart = () => {
    if (!videoElement.current) return;
    const showBlur = videoElement.current.readyState < 4;
    videoElement.current.style.opacity = showBlur ? '0' : '1';

    setIsIdleVideoShown(showBlur);
  };

  const handleVideoProgress = event => {
    if (!videoElement.current || !progressRef.current || !progressbarDoneRef.current) return;

    const manualChange = Number(event.target.value);
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;

    progressRef.current.value = manualChange;
    progressbarDoneRef.current.style.width = `${manualChange}%`;
  };

  const handleVideoSpeed = event => {
    if (!videoElement.current) return;

    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  const unmuteVideo = () => {
    setPlayerState({
      ...playerState,
      isMuted: false,
    });
  };

  const handleRewind = forward => {
    if (!videoElement.current) return;

    forward ? (videoElement.current.currentTime += 10) : (videoElement.current.currentTime -= 10);
  };

  const playVideoFromStart = () => {
    if (!videoElement.current) return;

    handleIsPlaying(true);

    videoElement.current.currentTime = 0;
  };

  return {
    playerState,
    isPlaying,
    togglePlay,
    playVideo,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    unmuteVideo,
    handleRewind,
    playVideoFromStart,
    handleOnLoadStart,
    isIdleVideoShown,
    references,
    isAnswerLoading,
  };
};

export default useVideoPlayer;
