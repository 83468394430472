import PropTypes from 'prop-types';

import styles from './ExternalUrl.module.scss';

function ExternalUrl({ externalUrl = '', externalUrlDescription = '' }) {
  if (!externalUrl) return;

  return (
    <div className={styles.urlPopup}>
      <a className={styles.externalUrl} href={externalUrl} target="_blank" rel="noreferrer">
        {externalUrlDescription || externalUrl}
      </a>
    </div>
  );
}

ExternalUrl.propTypes = {
  externalUrl: PropTypes.string,
};

export default ExternalUrl;
