import PropTypes from 'prop-types';

function ArrowRightIcon({ className = '', width = '14px', height = '22px', fill = '#000' }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.94135 0.794128L0.411621 3.32386L8.62876 11.5589L0.411621 19.794L2.94135 22.3238L13.7062 11.5589L2.94135 0.794128Z"
        fill={fill}
      />
    </svg>
  );
}

ArrowRightIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
};

export default ArrowRightIcon;
