import React from 'react';

import PropTypes from 'prop-types';

import { isFirefox } from 'react-device-detect';

import { VIDEO_SUPPORT } from '../../constants/experience';
import Loader from '../Loader/Loader';

// NOTE: Track element is used only so we can show subtitles in paragraph, so kind can be kind="metadata".
// If kind="caption" is used then it is shown on Mac and we don't want that.  Because of eslintjsx-a11y/media-has-caption rule  eslint is disabled.

/*  eslint-disable */
function MainVideo({
  src = '',
  handleOnClick,
  handleOnEnded,
  handleOnTimeUpdate,
  handleLoop,
  handleMuted,
  videoElementRef,
  subtitleUrl = '',
  className = '',
  handleOnLoadStart,
  isLoading = false,
  isConnectionSlow = false,
  handleReset,
  handleCustomAnalytics,
  handleOnDurationChange,
}) {
  const attributes = {
    id: 'video',
  };

  if (isFirefox) {
    attributes.key = subtitleUrl;
  }

  return (
    <>
      <video
        src={src}
        autoPlay
        loop={handleLoop}
        muted={handleMuted}
        type="video/mp4"
        ref={videoElementRef}
        onClick={handleOnClick}
        onEnded={() => {
          handleOnEnded();
          handleReset();
        }}
        onTimeUpdate={() => {
          handleOnTimeUpdate();
          handleCustomAnalytics();
        }}
        crossOrigin="anonymous"
        className={className}
        playsInline
        controlsList="nodownload"
        onLoadStart={handleOnLoadStart}
        onDurationChange={() => {
          handleReset();
          handleOnDurationChange();
        }}
        {...attributes}
      >
        <track kind="metadata" srcLang="en" label="English" default src={subtitleUrl} />
        <source type="video/mp4" />
        {VIDEO_SUPPORT}
      </video>
      {isConnectionSlow && isLoading && <Loader isLoading />}
    </>
  );
}

MainVideo.propTypes = {
  src: PropTypes.string,
  handleOnClick: PropTypes.func,
  className: PropTypes.string,
  handleOnEnded: PropTypes.func,
  handleOnTimeUpdate: PropTypes.func,
  handleLoop: PropTypes.bool,
  handleMuted: PropTypes.bool,
  videoElementRef: PropTypes.object,
  subtitleUrl: PropTypes.string,
  handleOnLoadStart: PropTypes.func,
};

export default MainVideo;
