import PropTypes from 'prop-types';

export function MicIcon({ className = '', width = '16px', height = '16px', fill = '#000' }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10.6486C8.79538 10.6478 9.55792 10.3457 10.1203 9.8086C10.6827 9.27153 10.9991 8.54334 11 7.78381V4.2028C11 3.443 10.6839 2.71432 10.1213 2.17707C9.55871 1.63981 8.79565 1.33798 8 1.33798C7.20435 1.33798 6.44129 1.63981 5.87868 2.17707C5.31607 2.71432 5 3.443 5 4.2028V7.78381C5.00089 8.54334 5.31725 9.27153 5.87967 9.8086C6.44208 10.3457 7.20462 10.6478 8 10.6486ZM6 4.2028C6 3.69626 6.21071 3.21048 6.58579 2.85231C6.96086 2.49414 7.46957 2.29292 8 2.29292C8.53043 2.29292 9.03914 2.49414 9.41421 2.85231C9.78929 3.21048 10 3.69626 10 4.2028V7.78381C10 8.29034 9.78929 8.77613 9.41421 9.1343C9.03914 9.49247 8.53043 9.69369 8 9.69369C7.46957 9.69369 6.96086 9.49247 6.58579 9.1343C6.21071 8.77613 6 8.29034 6 7.78381V4.2028Z"
        fill={fill}
      />
      <path
        d="M12.5 6.11267V7.78381C12.5 10.1533 10.4812 12.081 8 12.081C5.51875 12.081 3.5 10.1533 3.5 7.78381V6.11267H2.5V7.78381C2.5 10.5189 4.70075 12.772 7.5 13.014V15.662H8.5V13.014C11.2992 12.772 13.5 10.5189 13.5 7.78381V6.11267H12.5Z"
        fill={fill}
      />
    </svg>
  );
}

MicIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
};

export default MicIcon;
