import PropTypes from 'prop-types';

export function RewindIcon({ className = '', width = '50px', height = '50px', fill = '#ffffff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 26 26"
    >
      <path
        d="M12.6667 0C18.8667 0 24.1067 4.04 25.96 9.62667L22.8 10.6667C21.4 6.41333 17.3867 3.33333 12.6667 3.33333C10.0533 3.33333 7.69333 4.29333 5.84 5.84L9.33333 9.33333H0V0L3.46667 3.46667C5.93333 1.33333 9.13333 0 12.6667 0ZM9.33333 12V25.3333H6.66667V14.6667H4V12H9.33333ZM20 14.6667V22.6667C20 24.1467 18.8133 25.3333 17.3333 25.3333H14.6667C13.9594 25.3333 13.2811 25.0524 12.781 24.5523C12.281 24.0522 12 23.3739 12 22.6667V14.6667C12 13.9594 12.281 13.2811 12.781 12.781C13.2811 12.281 13.9594 12 14.6667 12H17.3333C18.0406 12 18.7189 12.281 19.219 12.781C19.719 13.2811 20 13.9594 20 14.6667ZM14.6667 14.6667V22.6667H17.3333V14.6667H14.6667Z"
        fill="white"
      />
    </svg>
  );
}

RewindIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default RewindIcon;
