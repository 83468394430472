import { Routes, Route } from 'react-router-dom';

import ExperiencePage from './pages/Experience.page';

function App() {
  return (
    <Routes>
      <Route path="/conversation" element={<ExperiencePage />} />
    </Routes>
  );
}

export default App;
