import React from 'react';

import { useExperienceContext } from '../../context/ExperienceContext';
import { useDrawerContext } from '../../context/DrawerContext';

import SearchQuestions from '../SearchQuestions/SearchQuestions';
import QuestionsGroup from '../QuestionGroup/QuestionsGroup';
import QuestionsList from '../QuestionsList/QuestionsList';
import QuestionIcon from '../icons/QuestionIcon';

import styles from './QuestionsSection.module.scss';

function QuestionsSection() {
  const { groupsError, experience } = useExperienceContext();
  const { openedPanel } = useDrawerContext();

  if (groupsError) return <>Error Groups...</>;

  return (
    <section
      className={`${styles.questionsWrapper} ${
        openedPanel.questions ? styles.showQuestions : styles.displayNone
      }`}
    >
      <div className={`${styles.header} ${experience.topRightLogo && styles.headerSpace}`}>
        <h2 className={styles.questionsTitle}>
          <QuestionIcon />
        </h2>
        {experience.topRightLogo && (
          <a
            className={styles.questionsLogo}
            href={experience.topRightLogo.externalUrl}
            target="_blank"
            rel="noreferrer"
          >
            <img src={experience.topRightLogo.url} alt="logo" className={styles.topRightLogo} />
          </a>
        )}
      </div>

      <QuestionsGroup />
      <SearchQuestions />
      <QuestionsList />
    </section>
  );
}

export default QuestionsSection;
