import PropTypes from 'prop-types';

export function KeyboardIcon({
  className = '',
  width = '50px',
  height = '50px',
  fill = '#ffffff',
  stroke,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      viewBox="0 0 34 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.68479 0.356201C2.7983 0.356201 1.94812 0.708356 1.32128 1.3352C0.69444 1.96204 0.342285 2.81222 0.342285 3.6987V20.4112C0.342285 21.2977 0.69444 22.1479 1.32128 22.7747C1.94812 23.4015 2.7983 23.7537 3.68479 23.7537H30.4248C31.3113 23.7537 32.1615 23.4015 32.7883 22.7747C33.4151 22.1479 33.7673 21.2977 33.7673 20.4112V3.6987C33.7673 2.81222 33.4151 1.96204 32.7883 1.3352C32.1615 0.708356 31.3113 0.356201 30.4248 0.356201H3.68479ZM3.68479 3.6987H30.4248V20.4112H3.68479V3.6987ZM5.35604 5.36995V8.71245H8.69854V5.36995H5.35604ZM10.3698 5.36995V8.71245H13.7123V5.36995H10.3698ZM15.3835 5.36995V8.71245H18.726V5.36995H15.3835ZM20.3973 5.36995V8.71245H23.7398V5.36995H20.3973ZM25.411 5.36995V8.71245H28.7535V5.36995H25.411ZM5.35604 10.3837V13.7262H8.69854V10.3837H5.35604ZM10.3698 10.3837V13.7262H13.7123V10.3837H10.3698ZM15.3835 10.3837V13.7262H18.726V10.3837H15.3835ZM20.3973 10.3837V13.7262H23.7398V10.3837H20.3973ZM25.411 10.3837V13.7262H28.7535V10.3837H25.411ZM10.3698 15.3975V18.74H23.7398V15.3975H10.3698Z"
        fill={fill}
      />
    </svg>
  );
}

KeyboardIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default KeyboardIcon;
