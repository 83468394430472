import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Loader from '../Loader/Loader';
/*  eslint-disable */

function HelperVideo({ src, className, helperVideoRef, isPlayingIdleVideo }) {
  const [videoLoaded, handleVideoLoaded] = useState(false);

  const handleOnLoadedData = () => {
    handleVideoLoaded(true);
  };

  const showLoader = !videoLoaded && isPlayingIdleVideo;

  return !showLoader ? (
    <video
      ref={helperVideoRef}
      className={className}
      src={src}
      autoPlay
      loop
      type="video/mp4"
      muted
      id="helper-video"
      crossOrigin="anonymous"
      playsInline
      preload="metadata"
      style={{ border: '0.2px black solid' }}
      onLoadedData={handleOnLoadedData}
      controlsList="nodownload"
    >
      <track kind="metadata" srcLang="en" label="English" />
    </video>
  ) : (
    <Loader isLoading />
  );
}

HelperVideo.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  helperVideoRef: PropTypes.object,
  isPlayingIdleVideo: PropTypes.bool,
};

export default React.memo(HelperVideo);
