import { useExperienceContext } from '../context/ExperienceContext';

import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import Video from '../components/Video/Video';
import QuestionsSection from '../components/QuestionsSection/QuestionsSection';
import Error from '../components/Error/Error';
import Loader from '../components/Loader/Loader';

import { Helmet } from 'react-helmet';
import { browserName } from 'react-device-detect';

const isFirefox = browserName === 'Firefox';

const parentUrl = isFirefox
  ? document.referrer
  : document.location.ancestorOrigins[document.location.ancestorOrigins.length - 1];
const url = document.location.href;
const isInIframe = window.location !== window.parent.location;

const urlReferrer = isInIframe ? parentUrl : url;

function ExperiencePage() {
  const { error, experience, loading, storedVideoUrl, questionsGroups, groupsError } =
    useExperienceContext();

  const title =
    `${experience?.conversation?.title} - ${experience?.conversation?.subtitle}`?.replace('.', '');

  if (loading) return <Loader isLoading />;
  if (error) return <Error error={error} />;
  if (groupsError) return <Error error={groupsError} />;
  if (!experience || !storedVideoUrl || questionsGroups.length === 0)
    return <Loader isLoading theme="dark" />;

  return (
    <>
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`}
        />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());

          gtag('config', '${process.env.REACT_APP_GTM_ID}', {
            conversation: '${title}',
            domain: '${urlReferrer}'
          });
          `}
        </script>
        <title>{title}</title>
      </Helmet>
      <LayoutWrapper theme={experience?.themeLayoutDesign}>
        {experience && (
          <>
            <Video buttonBackgroundColor={experience?.themeLayoutDesign.buttonBackgroundColor} />
            <QuestionsSection />
          </>
        )}
      </LayoutWrapper>
    </>
  );
}

export default ExperiencePage;
