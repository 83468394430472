import PropTypes from 'prop-types';

import convertHexToRGB from '../../utils/convertHexToRGB';
import styles from './LayoutWrapper.module.scss';

function LayoutWrapper({ children, theme = {} }) {
  const {
    backgroundColor,
    buttonBackgroundColor,
    logosBackgroundColor,
    questionsFontColor,
    bannerFontColor,
  } = theme;

  return (
    <main
      className={styles.main}
      style={{
        '--alpha': '0.7',
        '--primary-color': buttonBackgroundColor,
        '--primary-colorA': convertHexToRGB(buttonBackgroundColor),
        '--secondary-color': backgroundColor,
        '--secondary-colorA': convertHexToRGB(backgroundColor),
        '--tertiary-color': logosBackgroundColor,
        '--quaternary-color': questionsFontColor,
        '--quinary-color': bannerFontColor,
      }}
    >
      <div className={styles.holder}>{children}</div>
    </main>
  );
}

LayoutWrapper.propTypes = {
  children: PropTypes.object,
  theme: PropTypes.object,
};

export default LayoutWrapper;
