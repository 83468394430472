const imageUrl = `${process.env.REACT_APP_BLOB_URL}internet-speed-image.png`;
const downloadSize = 47795;

export const handleType = speed => {
  const speedMbps = Number(speed);

  switch (true) {
    case speedMbps <= 0.5:
      return '2g';
    case speedMbps <= 1.25:
      return '3g';
    case speedMbps <= 5:
      return 'regular-4g';
    default:
      return 'regular-4g';
  }
};

export async function MeasureConnectionSpeed() {
  return new Promise(resolve => {
    const request = new XMLHttpRequest();

    const startTime = new Date().getTime();
    const cacheBuster = `?nnn=${startTime}`;

    const src = imageUrl + cacheBuster;

    request.open('GET', src);
    request.timeout = 3000;

    const handleAbort = () => {
      request.abort();
      resolve(NaN);
    };

    request.addEventListener('timeout', () => {
      handleAbort();
    });

    request.addEventListener('error', () => {
      handleAbort();
    });

    request.addEventListener('load', () => {
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSize * 8;
      const speedBps = (bitsLoaded / duration).toFixed(2);
      const speedKbps = (speedBps / 1024).toFixed(2);
      const speedMbps = (speedKbps / 1024).toFixed(2);

      resolve(speedMbps);
    });

    request.send();
  });
}
