import PropTypes from 'prop-types';

function InstagramIcon({ className = '', width = '20px', height = '20px' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path d="M20,6.5C20,6.78 19.78,7 19.5,7H17.5C17.22,7 17,6.78 17,6.5V4.5C17,4.22 17.22,4 17.5,4H19.5C19.78,4 20,4.22 20,4.5M4.5,20C4.22,20 4,19.78 4,19.5V11H6.09C6.03,11.32 6,11.66 6,12C6,15.31 8.69,18 12,18C15.31,18 18,15.31 18,12C18,11.66 17.96,11.32 17.91,11H20V19.5C20,19.78 19.78,20 19.5,20M12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8M20,2H4C2.89,2 2,2.89 2,4V20C2,21.1 2.9,22 4,22H20C21.1,22 22,21.1 22,20V4C22,2.89 21.1,2 20,2Z" />
    </svg>
  );
}

InstagramIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default InstagramIcon;
