import { useState } from 'react';
import PropTypes from 'prop-types';
import { browserName, browserVersion, isIOS } from 'react-device-detect';

import { TYPE_QUESTION } from '../../constants/experience';

import styles from './TypeInYourQuestion.module.scss';

function TypeInYourQuestion({ handleTypedQuestion, unmuteVideo, setHeight }) {
  const [typedValue, setTypedValue] = useState('');
  const earlierVersionSafari = browserName === 'Safari' && browserVersion <= 13;

  const handleEnter = event => {
    if (event.key.toLowerCase() === 'enter' || event.key.toLowerCase() === 'tab') {
      handleTypedQuestion(event.target.value);
      setTypedValue('');
      unmuteVideo();
    }
  };

  const handleChange = event => {
    setTypedValue(event.target.value);
  };

  const handleOnFocus = () => {
    if (isIOS) setHeight(window.innerHeight * 0.68);
  };
  const handleOnBlur = () => {
    if (isIOS) setHeight(window.innerHeight);
  };

  return (
    <div className={styles.typeInQuestionWrapper}>
      <input
        className={`${styles.typeInQuestion} ${earlierVersionSafari && styles.inputFieldSafari}`}
        type="search"
        name="question"
        id="typeQuestionInput"
        placeholder={TYPE_QUESTION}
        onKeyDown={handleEnter}
        autoComplete="off"
        onChange={handleChange}
        value={typedValue}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </div>
  );
}

TypeInYourQuestion.propTypes = {
  handleTypedQuestion: PropTypes.func,
  unmuteVideo: PropTypes.func,
  setHeight: PropTypes.func,
};

export default TypeInYourQuestion;
