import { createContext, useContext } from 'react';

import useVideoPlayer from '../hooks/useVideoPlayer';

const VideoContext = createContext({});

export function useVideoContext() {
  return useContext(VideoContext);
}

export function VideoProvider({ children }) {
  const useVideoPlayerHook = useVideoPlayer();

  return <VideoContext.Provider value={useVideoPlayerHook}>{children}</VideoContext.Provider>;
}
