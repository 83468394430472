import PropTypes from 'prop-types';

import ArrowRightIcon from '../icons/ArrowRightIcon';

import styles from './ConversationBasicInformation.module.scss';

function ConversationBasicInformation({
  title = 'title',
  subtitle = '',
  handleShowDescription,
  callToActionUrl = '',
  shouldShowDescription = '',
  showDescription = false,
  callToActionText = '',
}) {
  return (
    <div className={styles.basicInfoLeft}>
      {title && <h3 className={styles.basicInfoName}>{title}</h3>}

      <p className={styles.basicInfoSocial}>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </p>
      {callToActionUrl && callToActionText && (
        <a href={callToActionUrl} target="_blank" rel="noreferrer" className={styles.btnWebsite}>
          {callToActionText}
        </a>
      )}
      {shouldShowDescription && (
        <button
          onClick={handleShowDescription}
          type="button"
          className={`${styles.buttonToggle} ${showDescription && styles.openedDescription}`}
        >
          <ArrowRightIcon />
        </button>
      )}
    </div>
  );
}

ConversationBasicInformation.propTypes = {
  subtitle: PropTypes.string,
  shouldShowDescription: PropTypes.string,
  showDescription: PropTypes.bool,
  handleShowDescription: PropTypes.func,
  title: PropTypes.string,
  callToActionUrl: PropTypes.string,
  callToActionText: PropTypes.string,
};

export default ConversationBasicInformation;
