import PropTypes from 'prop-types';

import LinkedinIcon from '../icons/LinkedinIcon';
import InstagramIcon from '../icons/InstagramIcon';
import WebsiteIcon from '../icons/WebsiteIcon';

import styles from './ConversationDescription.module.scss';

function ConversationDescription({ brief, website = '', instagram = '', linkedIn = '' }) {
  return (
    <div className={styles.basicInfoRight}>
      <div className={styles.scrollable}>
        <p className={styles.text}>{brief}</p>
      </div>
      {linkedIn && (
        <button type="button" className={styles.socialButton}>
          <a href={linkedIn} target="_blank" rel="noreferrer">
            <LinkedinIcon className={styles.linkedinIcon} />
          </a>
        </button>
      )}
      {instagram && (
        <button type="button" className={styles.socialButton}>
          <a href={instagram} target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
        </button>
      )}
      {website && (
        <button type="button" className={styles.socialButton}>
          <a href={website} target="_blank" rel="noreferrer">
            <WebsiteIcon />
          </a>
        </button>
      )}
    </div>
  );
}

ConversationDescription.propTypes = {
  brief: PropTypes.string,
  website: PropTypes.string,
  linkedIn: PropTypes.string,
  instagram: PropTypes.string,
};

export default ConversationDescription;
