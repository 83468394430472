import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ExperienceProvider } from './context/ExperienceContext';
import { DrawerProvider } from './context/DrawerContext';
import { VideoProvider } from './context/VideoContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ExperienceProvider>
        <DrawerProvider>
          <VideoProvider>
            <App />
          </VideoProvider>
        </DrawerProvider>
      </ExperienceProvider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
