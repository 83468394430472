import { useEffect, useState } from 'react';

import { sendAnalyticToPublitio } from '../api/requests';

import sha1 from 'sha1';

const initValueForPercentages = {
  five: null,
  ten: null,
  twentyfive: null,
  fifty: null,
  seventyfive: null,
  ninetyfive: null,
  hundred: null,
};

const useCustomAnalytics = (videoElement, videoId) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [percentages, setPercentages] = useState(initValueForPercentages);
  const [duration, setDuration] = useState(null);
  const [watchedDuration, setWatchedDuration] = useState(0);
  const [watchedPercent, setWatchedPercent] = useState(0);
  const [timestamps, setTimestamps] = useState([]);
  const [hash, setHash] = useState({
    playId: null,
    timenow: null,
    nounce: null,
  });

  const handleReset = () => {
    if (!videoElement.current) return;

    setPercentages(initValueForPercentages);
    setDuration(null);
    setWatchedDuration(0);
    setWatchedPercent(0);
    setTimestamps([]);
  };

  const getDuration = () => {
    if (!videoElement.current) return;

    setDuration(Math.floor(videoElement.current.duration));
  };

  const handleCustomAnalytics = () => {
    if (!videoElement.current && !videoId) return;

    if (!duration) getDuration();

    setCurrentTime(Number(videoElement.current.currentTime.toFixed(0)));

    if (timestamps.indexOf(currentTime) < 0) {
      setTimestamps(prev => [...prev, currentTime]);
    }

    setWatchedDuration(timestamps.length);
    setWatchedPercent(((100 / duration) * watchedDuration).toFixed(2));

    if (watchedPercent > 100) setWatchedPercent(100);
  };

  useEffect(() => {
    if (!duration) return;
    const fraction = Number(duration / 100);

    const countTime = percentage => Number((fraction * percentage).toFixed(0));

    setPercentages({
      five: countTime(5),
      ten: countTime(10),
      twentyfive: countTime(25),
      fifty: countTime(50),
      seventyfive: countTime(75),
      ninetyfive: countTime(95),
      hundred: countTime(100),
    });
  }, [duration]);

  useEffect(() => {
    const timenow = Date.now();
    const nounce = Math.floor(Math.random() * 99999);
    const playId = sha1(`${videoId}|${timenow}|${nounce}`);

    if (videoId) {
      setHash({ playId, timenow, nounce });
    } else {
      setHash({ playId: null, timenow: null, nounce: null });
    }
  }, [videoId]);

  useEffect(() => {
    switch (currentTime) {
      case percentages.five:
        setPercentages(prev => ({ ...prev, five: null }));
        !!videoId &&
          sendAnalyticToPublitio({
            play_id: hash.playId,
            timenow: hash.timenow,
            nounce: hash.nounce,
            file_code: videoId,
            watchedDuration,
            watchedPercent,
            event: percentages.five,
            timestamps,
          });
        break;
      case percentages.ten:
        setPercentages(prev => ({ ...prev, ten: null }));
        !!videoId &&
          sendAnalyticToPublitio({
            play_id: hash.playId,
            timenow: hash.timenow,
            nounce: hash.nounce,
            file_code: videoId,
            watchedDuration,
            watchedPercent,
            event: percentages.ten,
            timestamps,
          });
        break;
      case percentages.twentyfive:
        setPercentages(prev => ({ ...prev, twentyfive: null }));
        !!videoId &&
          sendAnalyticToPublitio({
            play_id: hash.playId,
            timenow: hash.timenow,
            nounce: hash.nounce,
            file_code: videoId,
            watchedDuration,
            watchedPercent,
            event: percentages.twentyfive,
            timestamps,
          });
        break;
      case percentages.fifty:
        setPercentages(prev => ({ ...prev, fifty: null }));
        !!videoId &&
          sendAnalyticToPublitio({
            play_id: hash.playId,
            timenow: hash.timenow,
            nounce: hash.nounce,
            file_code: videoId,
            watchedDuration,
            watchedPercent,
            event: percentages.fifty,
            timestamps,
          });
        break;
      case percentages.seventyfive:
        setPercentages(prev => ({ ...prev, seventyfive: null }));
        !!videoId &&
          sendAnalyticToPublitio({
            play_id: hash.playId,
            timenow: hash.timenow,
            nounce: hash.nounce,
            file_code: videoId,
            watchedDuration,
            watchedPercent,
            event: percentages.seventyfive,
            timestamps,
          });
        break;
      case percentages.ninetyfive:
        setPercentages(prev => ({ ...prev, ninetyfive: null }));
        !!videoId &&
          sendAnalyticToPublitio({
            play_id: hash.playId,
            timenow: hash.timenow,
            nounce: hash.nounce,
            file_code: videoId,
            watchedDuration,
            watchedPercent,
            event: percentages.ninetyfive,
            timestamps,
          });
        break;
      case percentages.hundred:
        setPercentages(prev => ({ ...prev, hundred: null }));
        !!videoId &&
          sendAnalyticToPublitio({
            play_id: hash.playId,
            timenow: hash.timenow,
            nounce: hash.nounce,
            file_code: videoId,
            watchedDuration,
            watchedPercent,
            event: percentages.hundred,
            timestamps,
          });
        break;
      default:
    }
  }, [currentTime]);

  useEffect(() => handleReset(), [videoId]);

  return {
    handleCustomAnalytics,
    handleReset,
    getDuration,
  };
};

export default useCustomAnalytics;
