import React from 'react';

import { browserName, browserVersion } from 'react-device-detect';

import { useExperienceContext } from '../../context/ExperienceContext';

import { SEARCH_QUESTIONS } from '../../constants/experience';

import styles from './SearchQuestions.module.scss';

function SearchQuestions() {
  const { questionsGroups, setFilteredQuestions, setSelectedGroup } = useExperienceContext();

  const earlierVersionSafari = browserName === 'Safari' && browserVersion <= 13;

  const handleSearchInput = event => {
    const searchTerm = event.target.value.toLowerCase().trim();
    const allQuestions = questionsGroups[0]?.questions;
    if (searchTerm.length === 0) setFilteredQuestions(allQuestions);

    const filteredQuestions = allQuestions.filter(question =>
      question.text.toLowerCase().includes(searchTerm)
    );

    return setFilteredQuestions(filteredQuestions);
  };

  if (!questionsGroups) return;

  return (
    <div className={styles.searchQuestionsWrapper}>
      <input
        className={`${styles.searchQuestions} ${earlierVersionSafari && styles.inputFieldSafari}`}
        type="search"
        placeholder={SEARCH_QUESTIONS}
        onFocus={() => setSelectedGroup('All')}
        onChange={handleSearchInput}
      />
    </div>
  );
}

export default SearchQuestions;
