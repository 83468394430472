import PropTypes from 'prop-types';

export function AskAQuestionIcon({
  className = '',
  width = '132px',
  height = '45px',
  fill = 'black',
}) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 132 45" fill={fill}>
      <path
        d="M26.5222 40.186C26.4661 39.7986 26.2726 39.4419 25.9746 39.1764C25.6766 38.9108 25.2924 38.7526 24.8872 38.7287C24.482 38.7049 24.0807 38.8167 23.7513 39.0454C23.422 39.274 23.1848 39.6053 23.0801 39.9832C22.9753 40.361 23.0095 40.7621 23.1767 41.1185C23.3439 41.4749 23.634 41.7646 23.9976 41.9384C24.3612 42.1123 24.7761 42.1597 25.1719 42.0726C25.5676 41.9854 25.9199 41.7691 26.169 41.4603C26.3149 41.2822 26.4215 41.0774 26.4822 40.8582C26.5429 40.6391 26.5566 40.4104 26.5222 40.186Z"
        fill={fill}
      />
      <path
        d="M17.7667 33.971C17.7042 33.971 17.6443 33.9646 17.5826 33.9622L21.581 39.1084L24.3458 37.1231L22.9921 35.3737C24.3648 34.1953 25.2653 32.5906 25.537 30.8388C25.8087 29.0869 25.4344 27.2988 24.4793 25.7853C23.5241 24.2717 22.0485 23.1286 20.309 22.5546C18.5695 21.9807 16.6762 22.0122 14.9584 22.6437C13.2405 23.2753 11.8068 24.4669 10.9065 26.0113C10.0062 27.5557 9.69631 29.3552 10.0307 31.097C10.3651 32.8387 11.3226 34.4124 12.7368 35.5445C14.1509 36.6766 15.9322 37.2953 17.7709 37.2932C17.8925 37.2932 18.0108 37.2828 18.1299 37.2756L14.2864 32.3292C13.6735 31.5704 13.3455 30.6339 13.356 29.6725C13.3665 28.7111 13.7148 27.7814 14.3442 27.0352C14.9736 26.289 15.8469 25.7702 16.8217 25.5636C17.7964 25.3569 18.8151 25.4745 19.7116 25.8972C20.608 26.3199 21.3293 27.0228 21.7579 27.8911C22.1864 28.7595 22.2969 29.7421 22.0712 30.6787C21.8456 31.6153 21.2972 32.4506 20.5155 33.0483C19.7337 33.6461 18.7648 33.9711 17.7667 33.9702V33.971Z"
        fill={fill}
      />
      <path
        d="M31.7462 23.1899V32.5359C31.7462 33.647 32.0754 34.4838 32.7339 35.0464C33.4084 35.609 34.3398 35.8903 35.5282 35.8903C36.7327 35.8903 37.6642 35.609 38.3226 35.0464C38.9971 34.4838 39.3344 33.647 39.3344 32.5359V23.1899H42.0806V32.4937C42.0806 33.6892 41.7835 34.7018 41.1892 35.5316C40.595 36.3615 39.8001 36.9803 38.8044 37.3882C37.8087 37.7961 36.7086 38 35.5041 38C34.2997 38 33.1996 37.7961 32.2039 37.3882C31.2243 36.9803 30.4454 36.3615 29.8672 35.5316C29.2891 34.7018 29 33.6892 29 32.4937V23.1899H31.7462Z"
        fill={fill}
      />
      <path
        d="M48.4987 25.1308V29.4346H54.2802V31.3966H48.4987V35.8903H55.0028V37.8523H45.7525V23.1688H55.0028V25.1308H48.4987Z"
        fill={fill}
      />
      <path
        d="M63.9982 38C62.874 38 61.8622 37.8312 60.9629 37.4937C60.0636 37.1421 59.3569 36.6498 58.843 36.0169C58.3291 35.384 58.0722 34.6456 58.0722 33.8017H61.0111C61.0753 34.4346 61.3564 34.955 61.8542 35.3629C62.3681 35.7707 63.0828 35.9747 63.9982 35.9747C64.9457 35.9747 65.6844 35.7778 66.2144 35.384C66.7444 34.9761 67.0093 34.4557 67.0093 33.8228C67.0093 33.3305 66.8407 32.9297 66.5035 32.6203C66.1823 32.3108 65.7728 32.0717 65.2749 31.903C64.7931 31.7342 64.1186 31.5513 63.2514 31.3544C62.1593 31.1013 61.268 30.8481 60.5775 30.5949C59.903 30.3277 59.3248 29.9198 58.843 29.3713C58.3612 28.8228 58.1203 28.0914 58.1203 27.1772C58.1203 26.3333 58.3612 25.5949 58.843 24.962C59.3248 24.3291 59.9993 23.8439 60.8665 23.5063C61.7338 23.1688 62.7375 23 63.8777 23C65.4997 23 66.8247 23.3586 67.8525 24.0759C68.8963 24.7792 69.4745 25.7496 69.5869 26.9873H66.5516C66.5035 26.4529 66.2144 25.9958 65.6844 25.616C65.1544 25.2363 64.4559 25.0464 63.5886 25.0464C62.8017 25.0464 62.1593 25.2222 61.6615 25.5738C61.1636 25.9255 60.9147 26.4318 60.9147 27.0928C60.9147 27.5429 61.0673 27.9156 61.3724 28.211C61.6936 28.4923 62.0951 28.7173 62.5769 28.8861C63.0587 29.0549 63.7171 29.2377 64.5522 29.4346C65.6603 29.7018 66.5597 29.9691 67.2502 30.2363C67.9569 30.5035 68.5511 30.9184 69.0329 31.481C69.5307 32.0295 69.7796 32.7679 69.7796 33.6962C69.7796 34.4416 69.5468 35.1449 69.081 35.8059C68.6314 36.4669 67.9649 37.0014 67.0816 37.4093C66.2144 37.8031 65.1866 38 63.9982 38Z"
        fill={fill}
      />
      <path
        d="M83.9325 23.1899V25.1519H79.476V37.8523H76.7298V25.1519H72.2492V23.1899H83.9325Z"
        fill={fill}
      />
      <path d="M89.5563 23.1899V37.8523H86.8101V23.1899H89.5563Z" fill={fill} />
      <path
        d="M100.895 38C99.3367 38 97.8994 37.6835 96.5825 37.0506C95.2817 36.4037 94.2458 35.5105 93.475 34.3713C92.7202 33.218 92.3427 31.9241 92.3427 30.4895C92.3427 29.0549 92.7202 27.7679 93.475 26.6287C94.2458 25.4895 95.2817 24.6034 96.5825 23.9705C97.8994 23.3235 99.3367 23 100.895 23C102.468 23 103.906 23.3235 105.207 23.9705C106.523 24.6034 107.559 25.4895 108.314 26.6287C109.069 27.7679 109.446 29.0549 109.446 30.4895C109.446 31.9241 109.069 33.218 108.314 34.3713C107.559 35.5105 106.523 36.4037 105.207 37.0506C103.906 37.6835 102.468 38 100.895 38ZM100.895 35.9114C102.003 35.9114 102.99 35.6934 103.857 35.2574C104.725 34.8073 105.399 34.1744 105.881 33.3586C106.379 32.5288 106.628 31.5724 106.628 30.4895C106.628 29.4065 106.379 28.4571 105.881 27.6414C105.399 26.8256 104.725 26.1997 103.857 25.7637C102.99 25.3277 102.003 25.1097 100.895 25.1097C99.7864 25.1097 98.7987 25.3277 97.9315 25.7637C97.0643 26.1997 96.3817 26.8256 95.8839 27.6414C95.4021 28.4571 95.1612 29.4065 95.1612 30.4895C95.1612 31.5724 95.4021 32.5288 95.8839 33.3586C96.3817 34.1744 97.0643 34.8073 97.9315 35.2574C98.7987 35.6934 99.7864 35.9114 100.895 35.9114Z"
        fill={fill}
      />
      <path
        d="M126 37.8523H123.254L114.991 26.903V37.8523H112.245V23.1688H114.991L123.254 34.097V23.1688H126V37.8523Z"
        fill={fill}
      />
      <path
        d="M53.24 12.83H48.984L48.2 15H46.856L50.384 5.298H51.854L55.368 15H54.024L53.24 12.83ZM52.876 11.794L51.112 6.866L49.348 11.794H52.876ZM59.9996 15.098C59.3556 15.098 58.7769 14.986 58.2636 14.762C57.7596 14.5287 57.3629 14.2113 57.0736 13.81C56.7843 13.3993 56.6349 12.928 56.6256 12.396H57.9836C58.0303 12.8533 58.2169 13.2407 58.5436 13.558C58.8796 13.866 59.3649 14.02 59.9996 14.02C60.6063 14.02 61.0823 13.8707 61.4276 13.572C61.7823 13.264 61.9596 12.872 61.9596 12.396C61.9596 12.0227 61.8569 11.7193 61.6516 11.486C61.4463 11.2527 61.1896 11.0753 60.8816 10.954C60.5736 10.8327 60.1583 10.702 59.6356 10.562C58.9916 10.394 58.4736 10.226 58.0816 10.058C57.6989 9.89 57.3676 9.62867 57.0876 9.274C56.8169 8.91 56.6816 8.42467 56.6816 7.818C56.6816 7.286 56.8169 6.81467 57.0876 6.404C57.3583 5.99333 57.7363 5.676 58.2216 5.452C58.7163 5.228 59.2809 5.116 59.9156 5.116C60.8303 5.116 61.5769 5.34467 62.1556 5.802C62.7436 6.25933 63.0749 6.866 63.1496 7.622H61.7496C61.7029 7.24867 61.5069 6.922 61.1616 6.642C60.8163 6.35267 60.3589 6.208 59.7896 6.208C59.2576 6.208 58.8236 6.348 58.4876 6.628C58.1516 6.89867 57.9836 7.28133 57.9836 7.776C57.9836 8.13067 58.0816 8.42 58.2776 8.644C58.4829 8.868 58.7303 9.04067 59.0196 9.162C59.3183 9.274 59.7336 9.40467 60.2656 9.554C60.9096 9.73133 61.4276 9.90867 61.8196 10.086C62.2116 10.254 62.5476 10.52 62.8276 10.884C63.1076 11.2387 63.2476 11.724 63.2476 12.34C63.2476 12.816 63.1216 13.264 62.8696 13.684C62.6176 14.104 62.2443 14.4447 61.7496 14.706C61.2549 14.9673 60.6716 15.098 59.9996 15.098ZM70.4144 15L66.3964 10.548V15H65.1224V5.242H66.3964V9.764L70.4284 5.242H72.0384L67.6144 10.128L72.0804 15H70.4144ZM83.0037 12.83H78.7477L77.9637 15H76.6197L80.1477 5.298H81.6177L85.1317 15H83.7877L83.0037 12.83ZM82.6397 11.794L80.8757 6.866L79.1117 11.794H82.6397Z"
        fill={fill}
      />
    </svg>
  );
}

AskAQuestionIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default AskAQuestionIcon;
