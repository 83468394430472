export const VIDEO_SUPPORT = "Sorry, your browser doesn't support videos.";
export const CC_TEXT = 'Subtitles';
export const SEARCH_QUESTIONS = 'Just search a question...';
export const TYPE_QUESTION = 'Type in your question';
export const SPEECH_TOOLTIP = 'To ask a question hold the button';
export const INVALID_URL = 'Invalid URL';
export const CONVERSATION_ID_MESSAGE = 'Conversation id is missing!';

export const ENDPOINTS = {
  CONVERSATIONS: 'Conversations',
  DISTRIBUTED_CONVERSATIONS: 'DistributedConversations',
  QUESTION_GROUPS: 'questionGroups',
  GENERATE_TOKEN: 'generateToken',
};
