import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import styles from './VideoProgressBar.module.scss';

function VideoProgressBar({
  progressRef,
  handleVideoProgress,
  progressbarDoneRef,
  shouldShowProgressBar = false,
}) {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      progressRef.current.value = 0;
    };
  }, []);

  return (
    <div
      className={`${styles.videoProgressControls} ${
        shouldShowProgressBar ? styles.displayProgressBar : styles.hideProgressBar
      }`}
    >
      <div ref={progressbarDoneRef} className={styles.progressInputDone} />
      <input
        type="range"
        min="0"
        max="100"
        step="0.1"
        ref={progressRef}
        onChange={handleVideoProgress}
        className={styles.progressInput}
      />
    </div>
  );
}

VideoProgressBar.propTypes = {
  progressRef: PropTypes.object,
  progressbarDoneRef: PropTypes.object,
  handleVideoProgress: PropTypes.func,
  shouldShowProgressBar: PropTypes.bool,
};

export default VideoProgressBar;
