import api from './api.instance';
import publitioApi from './publitioApi.instance';
import { ENDPOINTS } from '../constants/experience';
import { browserName } from 'react-device-detect';

const isFirefox = browserName === 'Firefox';

const parentUrl = isFirefox
  ? document.referrer
  : document.location.ancestorOrigins[document.location.ancestorOrigins.length - 1];
const url = document.location.href;
const isInIframe = window.location !== window.parent.location;

const urlReferrer = isInIframe ? parentUrl : url;

export const getDistributedConversationById = conversationId =>
  api.get(`${ENDPOINTS.DISTRIBUTED_CONVERSATIONS}/${conversationId}`, {
    headers: {
      urlReferrer,
    },
  });

export const getAuthTokenForSTT = () =>
  api.get(`${ENDPOINTS.CONVERSATIONS}/${ENDPOINTS.GENERATE_TOKEN}`);

export const getQuestionGroups = conversationId =>
  api.get(`${ENDPOINTS.CONVERSATIONS}/${conversationId}/${ENDPOINTS.QUESTION_GROUPS}`);

export const getVideoForAudio = (
  conversationId,
  question,
  DistributedConversationId,
  InteractionType
) =>
  api.get(`Videos?question=${question}&conversationId=${conversationId}`, {
    headers: {
      DistributedConversationId,
      InteractionType,
    },
  });

export const sendClickedQuestionInteraction = (distributedConversationId, videoId) =>
  api.post(`Videos/${videoId}/analytics/direct/${distributedConversationId}`);

export const sendAnalyticToPublitio = analyticsDto => {
  publitioApi.post(`stats/play/${analyticsDto.file_code}`, analyticsDto);
};
