import axios from 'axios';

const publitioApi = axios.create({
  baseURL: process.env.REACT_APP_PUBLITIO_URL,
});

publitioApi.interceptors.response.use(
  response => response,
  error => {
    console.error(error.message);
  }
);

export default publitioApi;
